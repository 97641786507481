//对接Rlink 共用获取函数
export default {
	data() {
		return {
			DeviceStatusObj:{
			   	ONLINE: '在线',
			   	OFFLINE: '离线',
			   	INACTIVE: '未激活',
			},
			AlarmTypeObj:{
			   	run: '运行',
			   	fault: '故障',
			},
			AlarmLevelObj:{
			   	info: '提示',
			   	warn: '一般',
			   	error: '严重',
			},
			AlarmStatusObj:{
			   	alarm: '告警',
			   	normal: '恢复',
			},
			OrderStatusObj:{
				1:'待领取',
				2:'待执行',
				3:'执行中',
				4:'待验收',
				5:'已完成',
				6:'报单被驳回',
				7:'验收被驳回',
				8:'报单已撤销',
				9:'已关单',
			},
		}
	},
	components: {},
	watch: {},
	created() {},
	methods: {
		
		async getAlarmDetailById(alarmUUID) {
			//通过alarmUUID 获取告警信息
			let backData;
			let params = {
				alarmUUID: alarmUUID,
			}
			let res = await this.ApiHttp('/order/tenant/orderFill/alarm/findAlarmDetail', params);
			if(res) {
				if(res.alarmLevel) res.alarmLevelStr=this.AlarmLevelObj[res.alarmLevel];
				if(res.alarmStatus) res.alarmStatusStr=this.AlarmStatusObj[res.alarmStatus];
				if(res.alarmType) res.alarmTypeStr=this.AlarmTypeObj[res.alarmType];
				if(res.timestamp) res.timestampStr=this.common.getTimeMmss(res.timestamp);//告警时间
				if(res.alarmTimestamp) res.alarmTimestampStr=this.common.getTimeMmss(res.alarmTimestamp);
				if(res.normalTimestamp) res.normalTimestampStr=this.common.getTimeMmss(res.normalTimestamp);//恢复时间
				res.alarmImgList=res.alarmImgList||[];
				if(res.alarmImgList){//告警图片
					let Arr=[];
					res.alarmImgList.map(it=>{
						Arr.push({fileId:it,fileType:'image/jpeg'})
					})
					res.alarmImgList=Arr;
				}
				res.normalImgList=res.normalImgList||[];//[35531,35530]
				if(res.normalImgList){//恢复图片
					let Arr=[];
					res.normalImgList.map(it=>{
						Arr.push({fileId:it,fileType:'image/jpeg'})
					})
					res.normalImgList=Arr;
				}
				if(res.unit){
					if(res.alarmValue){
						res.alarmValueUnit=res.alarmValue+res.unit;
					}
					if(res.normalValue){
						res.normalValueUnit=res.normalValue+res.unit;
					}
				}else{
					res.alarmValueUnit=res.alarmValue?res.alarmValue:'-';
					res.normalValueUnit=res.normalValue?res.normalValue:'-';
				}
				backData={...res}
			}else{
				this.$message.warning("获取告警详情失败！");
			}
			return backData
		},
		
		async getDeviceBasicsInfo(deviceId){
			//通过设备id 获取基础设备信息
			let backData;
			let res = await this.ApiHttp('/base/tenant/device/get/'+deviceId);
			if(res) {
				if(res.creationDate) res.creationDateStr=this.common.getTimeMmss(res.creationDate);//创建时间
				if(res.lastUpdateDate) res.lastUpdateDateStr=this.common.getTimeMmss(res.lastUpdateDate);//更新时间
				if(res.devicePropertiesInfoVOS){
					//处理第二次优化的设备数据  
					let resulArr=[];
					res.devicePropertiesInfoVOS.map(it=>{
						if(it.deviceStatus) it.deviceStatusStr=this.DeviceStatusObj[it.deviceStatus];
						if(it.moduleS){
							let concantArr=[]
							it.moduleS.map(it=>{
		           				if(it.properties){
		           					it.properties.map(_p=>{
		           						_p.moduleName=it.moduleName;
		           						_p.updateTimeStr='-';
		           						if(_p.updateTime){
		           							_p.updateTimeStr=this.common.getTimeMmss(_p.updateTime);
		           						}
		           						_p.valueStr='-';
		           						if(_p.value){
		           							_p.valueStr=_p.unit?_p.value+_p.unit:_p.value;
		           						}
		           					})
		           				}else{
		           					it.properties=[];
		           				}
		           				concantArr=concantArr.concat(it.properties);
		           			})
							it.isOpen=true;
							it.modulesArr=concantArr;
							it.rows = it.modulesArr;
							it.total = it.modulesArr.length;
						};
						resulArr.push(it)
					})
					res.deviceArrs=resulArr;
				}
				backData={...res}
			}else{
				this.$message.warning("获取设备详情失败！");
			}
			return backData
			
		},
			
	
		async getDeviceSarmtInfo(params){
			//通过设备pkdn 获取智能设备信息 param：{productKey:'',rlinkDeviceName:''}
			let backData;
			let res = await this.ApiHttp('/base/tenant/device/findSmartDeviceDataInfo', params,'post');
			if(res) {
				if(res.deviceStatus) res.deviceStatusStr=this.DeviceStatusObj[res.deviceStatus];
				if(res.moduleS){
					let concantArr=[]
					res.moduleS.map(it=>{
           				if(it.properties){
           					it.properties.map(_p=>{
           						_p.moduleName=it.moduleName;
           						_p.updateTimeStr='-';
           						if(_p.updateTime){
           							_p.updateTimeStr=this.common.getTimeMmss(_p.updateTime);
           						}
           						_p.valueStr='-';
           						if(_p.value){
           							_p.valueStr=_p.unit?_p.value+_p.unit:_p.value;
           						}
           						
           					})
           				}else{
           					it.properties=[];
           				}
           				concantArr=concantArr.concat(it.properties);
           			})
					res.modulesArr=concantArr;
					res.rows = res.modulesArr;
					res.total = res.modulesArr.length;
				};
				backData={...res}
			}
			return backData
			
		},
		
		
		async initAlarmlist(params){
			let backData;
			let res = await this.ApiHttp('/order/tenant/orderFill/alarm/findAlarmPage',params);
			if(res) {
				if(res.rows){
					res.rows.map(it=>{
//						if(it.alarmStatus=='normal'){
//							//恢复时   告警值跟时间 取恢复的数值
//							it.alarmValue=it.normalValue;
//							it.alarmTimestampStr=this.common.getTimeMmss(it.normalTimestamp);// 取恢复时间
//						
//						}else{
//							it.alarmTimestampStr=this.common.getTimeMmss(it.alarmTimestamp);//告警时间
//						}
						it.alarmTimestampStr=this.common.getTimeMmss(it.alarmTimestamp);//告警时间
						it.valUnit=it.alarmValue;
						if(it.unit) it.valUnit=it.alarmValue+it.unit;
						it.alarmValue=it.valUnit;
						if(it.alarmLevel) it.alarmLevelStr=this.AlarmLevelObj[it.alarmLevel];
						if(it.alarmStatus) it.alarmStatusStr=this.AlarmStatusObj[it.alarmStatus];
						if(it.alarmType) it.alarmTypeStr=this.AlarmTypeObj[it.alarmType];
						if(it.timestamp) it.timestampStr=this.common.getTimeMmss(it.timestamp);//告警时间
						
					})
				}
				backData={...res}
			}
			return backData
		},
		
		async initOrderRecordlist(params){
			let backData;
			let res = await this.ApiHttp('/order/tenant/orderFill/conditionalQuery',params);
			if(res) {
				
				if(res.rows){
					res.rows.map(it=>{
						it.repairPeople=it.handlePersonName;//维修人员
						if(it.orderStatus == 1) it.repairPeople='待领取';
						if(it.orderStatus == 6 ) it.repairPeople='/';
						
						it.orderStatusStr='';//工单状态
						if(it.orderStatus) it.orderStatusStr=this.OrderStatusObj[it.orderStatus];
						
						if(it.alarmVo){
							let alarmVo=it.alarmVo;
							if(!alarmVo.propertyUnit) alarmVo.propertyUnit='';
							let showAlarmValue=alarmVo.alarmValue+' '+alarmVo.propertyUnit;
          					if(!alarmVo.alarmValue) showAlarmValue='-';
          					if(!alarmVo.propertyDescription) alarmVo.propertyDescription='-';
							it.problemDesc='告警类型:'+ this.AlarmTypeObj[alarmVo.alarmType]+'; 告警内容:'+alarmVo.alarmContent+'; 告警值:'+showAlarmValue+'; 告警时间:'+this.common.getTimeMmss(alarmVo.alarmTimestamp)+'; 告警参数:'+alarmVo.propertyDescription
						}
						
					})
				}
				backData={...res}
			}
			return backData
		},
		
		
		
		async findAlarmPropertyPage(params) {
	    	/** 属性上的历史记录  
	    	 * current 当前页
	    	 * rowCount 每页显示数据量 
	    	 * startTime 开始时间
			 * endTime 结束时间
			 * propertieName:
	    	 */
	    	let backData;
			let res = await this.ApiHttp('/order/tenant/orderFill/alarm/findPropertyPage',params);
			if(res) {
				backData={...res}
			}
			return backData
    	},
    
    	async findPatrolPage(params) {
	    	//设备详情，获取巡检记录列表  relationId=deviceId
	    	let backData;
			let res = await this.ApiHttp('/inspection/tenant/inspection/job/relationDeviceList',params);
			if(res) {
				
				if(res.rows){
					res.rows.map(it=>{
						it.jobDutyListStr='';
						it.jobDutyList.map(itJb=>it.jobDutyListStr+=itJb.dutyName+'|');
						if(it.jobDutyListStr){
							let len=it.jobDutyListStr.length
							it.jobDutyListStr=it.jobDutyListStr.substring(0,len-1);
						}
					})
				}
				backData={...res}
			}
			return backData
    	},
    	
    	async findDeviceMaintenanceList(params) {
	    	//按设备查维保任务列表
	    	let backData;
			let res = await this.ApiHttp('/inspection/tenant/inspection/job/relationDeviceMaintenanceList',params);
			if(res) {
				if(res.rows){
					res.rows.map(it=>{
						it.jobDutyListStr='';
					})
				}
				backData={...res}
			}
			return backData
    	},
    	
    	async findConditionalQuery(params) {
	    	//设备配件更换记录
	    	let backData;
			let res = await this.ApiHttp('/order/tenant/orderMateriel/conditionalQuery',params);
			if(res) {
				if(res.rows){
					res.rows.map(it=>{
						it.auditTimeStr=this.common.getTimeMmss(it.auditTime);
					})
				}
				backData={...res}
			}
			return backData
    	},
    
		
	},

}